




















import ResetPasswordForm from '../../components/auth/ResetPasswordForm.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'ForgotPasswordPage',

  components: {
    ResetPasswordForm,
  },
});
