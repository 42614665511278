









































import Vue from 'vue';
import { VForm } from '../../plugins/vuetifyTypes';
import { AuthService } from '../../services/auth/AuthService';
import { SnackbarType } from '../../services/snackbar/models/SnackbarType';
import { SnackbarService } from '../../services/snackbar/SnackbarService';

export default Vue.extend({
  name: 'ResetPasswordForm',

  data() {
    return {
      isLoading: false,
      isPasswordResetSuccessful: false,

      formData: {
        username: '',
      },
    };
  },

  methods: {
    async onSubmit(): Promise<void> {
      this.isLoading = true;
      try {
        const validation = (this.$refs.form as VForm).validate();
        if (validation) {
          this.isPasswordResetSuccessful = true;
          await AuthService.forgotPassword(this.formData.username);
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        SnackbarService.open(
          this.$t('snackbar.somethingWentWrong'),
          SnackbarType.Error
        );
        this.isLoading = false;
      }
    },
  },
});
